@charset "utf-8";

$primary: white;
$pink: #E61D73;

$label-color: $primary;

@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/components/level.sass";
@import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/form.sass";
@import "../node_modules/bulma/sass/elements/icon.sass";

@font-face {
  font-family: 'flamablackitalic';
  src: url('assets/fonts/flamablack_italic-webfont.woff2') format('woff2'),
       url('assets/fonts/flamablack_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'flamablackregular';
  src: url('assets/fonts/flamablack_regular-webfont.woff2') format('woff2'),
       url('assets/fonts/flamablack_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'flamabasic';
  src: url('assets/fonts/flama_basic-webfont.woff2') format('woff2'),
       url('assets/fonts/flama_basic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'flamabold';
  src: url('assets/fonts/flama_bold-webfont.woff2') format('woff2'),
       url('assets/fonts/flama_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body{
  overflow-x: hidden;
  background-image: url(./assets/img/bg.jpg);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

header{
  margin-bottom: 60px;
}

section.title{
  margin: 60px 0;
}

.graphic{
  position: absolute;
  right: 32px;
  top: 350px;
}

main h2.title{
  font-family : 'flamablackitalic', sans-serif;
  font-style : italic;
  font-size : 46px;
  line-height : 80px;
  color : $primary;
  text-transform: uppercase;
  margin-bottom: 60px;
}

.schedule{
  margin-bottom: 60px;
}

.schedule__hour {
  width: 150px;
}

.schedule__hour span
{
  display: block;
  font-family : 'flamablackitalic', sans-serif;
  font-size : 26px;
  line-height : 50px;
  color : $primary;
  background : $pink;
  border-radius : 0px 14px;
  text-align: center;
}

.schedule__text p {
  font-family : 'flamablackregular', sans-serif;
  font-size : 30px;
  position : relative;
  color : $primary;
  padding-left: 20px;
}

.schedule__text p span {
  display: block;
  font-size : 22px;
  margin-top: -10px;
}

.schedule .columns {
  margin-bottom: 24px;
}

.signup {
  background: $pink;
  padding-bottom: 30px;
}

.signup-header{
  padding: 16px 0;
}
.signup-header h2,
.signup-header h3{
  font-family : 'flamablackregular', sans-serif;
  font-size : 24px;
  color: $primary;
}

.signup-header h3{
  text-transform: uppercase;
}

.sponsors-ipiaget{
  display: flex;
  flex-direction: row;
}

.sponsors{
  width: 60%;
  background: $white;
  position: relative;
}

.ipiaget{
  width: 40%;
  text-align: right;
}

.ipiaget img,
.sponsors img{
  position: relative;
  z-index: 2;
  margin: 32px 0;
}

.sponsors::after{
  z-index: 1;
  content: '';
  position: absolute;
  left: 60px;
  top: 0; bottom: 0;
  background: $white;
  width: 100%;
  height: 100%;
  transform: skewX(-35deg);
}

.signup-header .title{
  font-family : 'flamablackregular', sans-serif;
  font-size : 24px;
  color : #FFFFFF;
}

footer .location-website{
  padding: 16px 0;
}

footer .location{
  font-family : 'flamablackregular', sans-serif;
  font-size : 20px;
  color: $primary;
}

footer .location span{
  font-family : 'flamabasic', sans-serif;
  padding-right: 10px;
}

footer a{
  color: $primary;
}
footer a:hover{
  color: $pink;
}

footer .website{
  width: 200px;
  text-align: right;
}

footer .website a{
  font-family : 'flamabold', sans-serif;
  font-size : 20px;
}

.label, .input, .textarea, .checkbox, .button{
  font-family : 'flamabasic', sans-serif;
}

.checkbox, .checkbox a{
  color: $primary;
}

.button{
  text-transform: uppercase;
}

.field.required .label::after{
  font-family : 'flamabasic', sans-serif;
  content: '*';
  display: inline-block;
  margin-left: 8px;
}

.img-piaget-digital-futuring{
  width: 100%;
  max-width: 1200px;
  padding-right: 100px;
}

.img-ipiaget{
  width: 100%;
  max-width: 549px;
  padding-left: 42px;
}

.graphic img{
  width: 100%;
  max-width: 600px;
  padding-left: 100px;
}

@media only screen and (max-width:1088px) {
  .container.is-fluid {
    margin-left: 32px;
    margin-right: 32px;
  }
}

/* Phone */
@media only screen and (max-width:768px) {
  .graphic{
    display: none;
  }

  .sponsors{
    width: 100%;
  }

  .ipiaget{
    display: none;
  }

  footer .website,
  .location-website .column{
    text-align: center;
    width: 100%;
  }

  .schedule__text p span {
    margin-top: 0;
  }
}

/* Tablet */
@media only screen and (min-width : 769px) and (max-width : 1024px) {

}
